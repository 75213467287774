



































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import CAddModal from '@/modules/budget-request/budget-gkkp/c-add-modal.vue';
import CNumberInput from '@/components/c-number-input/index.vue';
import store from '@/services/store';

@Component({
    name: 'c-limit-gu',
    components: {
        'c-add-modal': CAddModal,
        'c-number-input': CNumberInput
    }
})
export default class CGuLimit extends Vue {

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curPeriodProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private dataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curRegionProp!: any | null;

    @Prop({
        required: true,
        default: false
    })
    private editableProp!: boolean;

    private get editable() {
        return this.editableProp;
    }

    private progress = 0;
    private collapseAll = false;

    private curAbp: any | null = null;
    private curVariant: any | null = null;
    private curYear: number | null = null;
    private dataType: any | null = null;
    private curRegion: any | null = null;

    // -- список программ
    private progBase: any[] = [];
    private curIndxPrg: number | null = null; // программа к которой добавляется ГУ
    private guBase: any[] = [];
    // --параметры для модального добавления
    private nameDict = 'программы';
    private codeDict = 'prg';

    // ----
    private totalSum: any = { value1: 0, value2: 0, value3: 0 };
    private totalLimit: any = { value1: null, value2: null, value3: null };

    private dataLst: any [] = [];

    private checkedAll = false;
    
    /**
     * изменение фильтра
     * @param params 
     */
    public chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curVariant = params.curVariant;
        this.curYear = params.curYear;
        this.dataType = params.dataType;
        this.curRegion = params.regionProp; 
        this.loadAll();
    }

    private mounted() {
        this.curAbp = this.curAbpProp;
        this.curVariant = this.curVariantProp;
        if (this.curPeriodProp) { this.curYear = this.curPeriodProp.year; }
        this.dataType = this.dataTypeProp;
        this.curRegion = this.curRegionProp;
        this.loadAll();
    }

    private async loadAll() {
        this.loadGuLst();
        this.loadData();
        this.loadProgLst();
    }

    /**
     * загрузка данных
     */
    private async loadData() {
        this.totalSum = { value1: 0, value2: 0, value3: 0 };
        this.totalLimit = { value1: null, value2: null, value3: null };
        this.dataLst = [];
        if (!this.curAbp || !this.curVariant || !this.curYear || !this.dataType || !this.curRegion) {
            return;
        }
        this.progress = 50;
        const params = { abp: this.curAbp.abp, variant: this.curVariant.variant_uuid, curYear: this.curYear, dataType: this.dataType.code, region: this.curRegion.code };
        this.loadTotalLimit(params);
        this.dataLst = await this.loadSavedData(params);
        this.progress = 80;
        if (!this.dataLst.length)  { this.dataLst = await this.loadFirstData(params); }
        this.progress = 100;
    }


    private async loadProgLst() {
        if (!this.curAbp) { return; }
        let response: any = [];
        try {
            response = await fetch(`/api-py/get-dict-func-childs-by-parent-code/${encodeURI(JSON.stringify({type: 4, abp: this.curAbp.abp}))}`);
            response = await response.json();
            this.progBase = response;
            // if (this.progDevelopType !== null) { response = response.filter((item: any) => item.develop_type === 0); }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки программ', (error as Error).toString());
        }
    }

    private async loadGuLst() {
        if (!this.curAbp || !this.curRegion) { return; }
        let response: any = [];
        try {
            response = await fetch(`/api-py/dict-gu-by-region-abp/${this.curRegion.code}/${this.curAbp.abp}`);
            response = await response.json();
            this.guBase = response;
            // if (this.progDevelopType !== null) { response = response.filter((item: any) => item.develop_type === 0); }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки ГУ', (error as Error).toString());
        }
    }

    /**
     * загрузка всего лимита
     */
    private async loadTotalLimit(params: any) {  
        const url = `/api-py/get-budget-gkkp-limit-gu-total/${encodeURI(JSON.stringify(params))}`;
            let response: any = [];
            try {
                response = await fetch(url);
                if (response.status === 200) {
                    response = await response.json();
                    for (let i=1; i<4; i++) {
                        if (response[`value${i}`] === undefined) { 
                            response[`value${i}`] = null; 
                        } else {
                            response[`value${i}`] = Math.round(response[`value${i}`]*10) / 10;
                        }
                    }
                    this.totalLimit = response;
                } else {
                    makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu-total', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                response = [];
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu-total', (error as Error).toString());
            }
    }

    /**
     * загрузка сохранённых данных из budget_limit_gu 
     * @param params параметры
     */
     private async loadSavedData(params: any) {
        const url = `/api-py/get-budget-gkkp-limit-gu/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu', (error as Error).toString());
        }
        return this.getSavedData(response);
    }

    /**
     * сформировать данные из loadSavedData
     * @param response 
     */
    private getSavedData(response: any[]) {
        const result: any[] = [];
        response.sort((a: any, b: any) => (a.prg.prg > b.prg.prg) ? 1 : -1);
        for (const el of response) {
            if (!result.length || result[result.length - 1].prg.prg !== el.prg.prg) {
                result.push({prg: el.prg, guLst: [], sumVl1: el.sum_vl1, sumVl2: el.sum_vl2, sumVl3: el.sum_vl3,  plan: 0, value1: 0, value2: 0, value3: 0, collapse: this.collapseAll, checked: this.checkedAll});
            }

            const obj = Object.assign({proc1: 0, proc2: 0, proc3: 0, checked: this.checkedAll}, el);
            for (let i = 1; i < 4; i++) {
                result[result.length - 1][`value${i}`] += obj[`value${i}`];
                this.totalSum[`value${i}`] += obj[`value${i}`];
            }
           
            if (obj.plan) { obj.proc1 = Math.round(obj.value1/obj.plan*1000)/10; }
            for (let i = 2; i < 4; i++) {
                if (obj[`value${(i-1)}`]) { obj[`proc${i}`] = Math.round(obj[`value${i}`]/obj[`value${(i-1)}`]*1000)/10; }
            }
            result[result.length - 1].plan += obj.plan;
            delete el.prg;
            result[result.length-1].guLst.push(obj);
        }
        return result;
    }

    /** 
    *Загрузка первичных данных 
    */
    private async loadFirstData(params: any) {
        const url = `/api-py/get-budget-gkkp-limit-gu-first/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu', (error as Error).toString());
        }
        return await this.getFirstData(response);
        
    }

    /**
     * сформировать пер
     * @param response 
     */
    private getFirstData(response: any[]) {
        const result: any[] = [];
        response.sort((a: any, b: any) => (a.prg.prg > b.prg.prg) ? 1 : -1);
        for (const el of response) {
            if (!result.length || result[result.length - 1].prg.prg !== el.prg.prg) {
                /*if (result.length && result[result.length-1].guLst.length) {
                    result[result.length-1].collapse = this.collapseAll;
                }*/
                result.push({prg: el.prg, guLst: [], sumVl1: el.sum_vl1, sumVl2: el.sum_vl2, sumVl3: el.sum_vl3, plan: 0, value1: 0, value2: 0, value3: 0, collapse: this.collapseAll, checked: this.checkedAll});
            }
            if (el.gu) {
                const obj = Object.assign({proc1: 0, proc2: 0, proc3: 0, value1: 0, value2: 0, value3: 0, checked: this.checkedAll}, el);
                if (obj.plan) { 
                    result[result.length - 1].plan += obj.plan;
                }
                delete el.prg;
                result[result.length-1].guLst.push(obj);
            }
        }
        return result;
    }

    /**
     * ввод цифр
     */
    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    /**
     * сворачивать программы
     * @param indx индекс программ
     */
    private clkCollapse(indx: number | null) {
        if (indx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.dataLst) {
                this.$set(el, 'collapse', this.collapseAll);
            }
        } else {
            const collapse = !this.dataLst[indx].collapse;
            this.$set(this.dataLst[indx], 'collapse', collapse);
            if (this.collapseAll && !collapse) {
                for (const el of this.dataLst) {
                    if (el.collapse) { return; }
                }
                this.collapseAll = false;
            } else if (!this.collapseAll && collapse) {
                for (const el of this.dataLst) {
                    if (!el.collapse) { return; }
                }
                this.collapseAll = true;
            }
        }
    }

    /**
     * изменение значения лимита
     * @param obj объект guLst
     * @param fieldNumber номер поля 1-3 
     * @param indx индекс dataLst
     */
    private chgValue(obj: any, fieldNumber: string, indx: number) {
        const fieldName = `value${fieldNumber}`
        if (isNaN(obj[fieldName]) || obj[fieldName]==='') { this.$set(obj, fieldName, 0); }
        obj[fieldName] = (Math.round(parseFloat(obj[fieldName]) * 10))/10;

        if (obj.plan) { obj.proc1 = Math.round(obj.value1/obj.plan*1000)/10; }
        for (let i = 2; i < 4; i++) {
            if (obj[`value${(i-1)}`]) { obj[`proc${i}`] = Math.round(obj[`value${i}`]/obj[`value${(i-1)}`]*1000)/10; }
        }

        let value1 = 0;
        let value2 = 0;
        let value3 = 0;

        for (const el of this.dataLst[indx].guLst) {
            value1+=el.value1;
            value2+=el.value2;
            value3+=el.value3;
        }
        this.$set(this.dataLst[indx], 'value1', Math.round(value1*10)/10);
        this.$set(this.dataLst[indx], 'value2', Math.round(value2*10)/10);
        this.$set(this.dataLst[indx], 'value3', Math.round(value3*10)/10);
        const totalSum: any = { value1: 0, value2: 0, value3: 0 };
        for (const el of this.dataLst) {
            for (let i=1; i<4; i++) {
                totalSum[`value${i}`] += Math.round(el[`value${i}`]*10)/10;
            }
        }
        this.totalSum = totalSum;
    }

    private addGu(indx: number) {
        const dictLst = [];
        this.nameDict = 'ГУ';
        this.codeDict = 'code';
        for (const el of this.guBase) {
            let fl = false;
            for (let i = 0; i < this.dataLst[indx].guLst.length; i++) {
                if (this.dataLst[indx].guLst[i].gu.gu === el.code) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { dictLst.push(el); }
        }
        this.curIndxPrg = indx;

        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.addModalRef.openModal(dictLst);
    }

    /**
     * добавить программу
     */
    private addProg() {
        const dictLst = [];
        this.nameDict = 'программы';
        this.codeDict = 'prg';
        for (const el of this.progBase) {
            let fl = false;
            for (let i = 0; i < this.dataLst.length; i++) {
                if (this.dataLst[i].prg.prg === el.prg) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { dictLst.push(el); }
        }

        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.addModalRef.openModal(dictLst);
    }

    /**
     * кнопка добавить с модального окна
     */
    private async addModalClk(items: any[]) {
        if (this.codeDict === 'prg') { // добавление программ
            for (const el of items) {
                const obj = { guLst: [], plan: 0, prg: { prg: el.prg, name_ru: el.name_ru, name_kk: el.name_kk }, value1: 0, value2: 0, value3: 0, proc1: 0, proc2: 0, proc3: 0, collapse: this.collapseAll, checked: this.checkedAll }
                this.dataLst.push(obj);
            }
            this.dataLst.sort((a: any, b: any) => (a.prg.prg > b.prg.prg) ? 1 : -1);
            this.loadProgValSum(items);
        } else {
            if (this.curIndxPrg === null) { return; }
            const curPrg = this.dataLst[this.curIndxPrg].prg.prg
            const params: any = {prg: curPrg, guLst: [], curYear: this.curYear, abp: this.curAbp.abp};
            for (const el of items) {
                params.guLst.push(el.code);
            }

            const url = `/api-py/get-budget-gkkp-limit-gu-plan/${encodeURI(JSON.stringify(params))}`;
            let response: any = [];
            try {
                response = await fetch(url);
                if (response.status === 200) {
                    response = await response.json();
                } else {
                    makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu-plan', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                response = [];
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-gkkp-limit-gu-plan', (error as Error).toString());
            }
            let sumPlan = 0;
            for (const el of items) {
                const obj = {gu: { gu: el.code, name_ru: el.name_ru, name_kk: el.name_kk }, plan: 0, proc1: 0, proc2: 0, proc3: 0, value1: 0, value2: 0, value3: 0, checked: this.checkedAll};
                for (let i=0; i< response.length; i++) {
                    if (response[i].gu === el.code) {
                        obj.plan = response[i].plan;
                        sumPlan += obj.plan;
                        break;
                    }
                }
                this.dataLst[this.curIndxPrg].guLst.push(obj);
            }
            sumPlan = Math.round((sumPlan + this.dataLst[this.curIndxPrg].plan)*10)/10;
            this.dataLst[this.curIndxPrg].guLst.sort((a: any, b: any) => (a.gu.gu > b.gu.gu) ? 1 : -1);
            this.$set(this.dataLst[this.curIndxPrg], 'plan', sumPlan);
        }
    }

    /**
      * подгрузка суммы лимитов для программ из budget_cost_data
      */
    private async loadProgValSum(items: any[]) {
        if (!this.curAbp || !this.curVariant || !this.curYear  || !this.curRegion || !this.dataType || !items.length) {
            return;
        }
        
        const params: any = { abp: this.curAbp.abp, variant: this.curVariant.variant_uuid, curYear: this.curYear, prg: [], region: this.curRegion.code, dataType: this.dataType.code };
        for (const item of items) {
            params.prg.push(item.prg);
        }
        let response: any = [];
        try {
            response = await fetch(`/api-py/get-budget-gu-limit-prog-sum-by-prog/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                response = await response.json();
                this.setProgValSum(response);
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-gu-limit-prog-sum-by-prog', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки get-budget-gu-limit-prog-sum-by-prog', (error as Error).toString());
        }
    }

    /**
     * проставить суммы лимитов для программ из budget_limit_gu
     * @param response 
     */
     private setProgValSum(response: any[]) {
        for (const el of response) {
            for (let i = 0; i < this.dataLst.length; i++) {
                if (this.dataLst[i].prg.prg === el.prg) {
                    for (let j = 1; j < 4; j++) {
                        this.$set(this.dataLst[i], `sumVl${j}`, el[`sum_vl${j}`]);
                    }
                    break;
                }
            }
        }
    }

    /**
     *  сохранить
     */
    private async saveClk() {
        if (!this.editable) { return; }
        if (this.progress > 0 && this.progress < 100) { return; }
        
        if (this.totalLimit && this.totalSum && this.dataLst.length) {
            for (let i=1; i<4; i++) {
                if (this.totalLimit[`value${i}`]===null) {
                    if (this.totalSum[`value${i}`]) {
                        makeToast(this, 'danger', 'Сохранение', 'Сохранение не возможно, так как не определены общие лимиты по АБП (строка ВСЕГО ЛИМИТ не заполнена)');
                        return;
                    }
                } else if ((this.totalLimit[`value${i}`] - this.totalSum[`value${i}`])<0) { 
                    makeToast(this, 'danger', 'Сохранение', 'Остаток по лимиту не может быть отрицательным. Проверьте данные!');
                    return;
                 }
            }
        }
        const saveObj: any = { variant: this.curVariant.variant_uuid, abp: this.curAbp.abp, curYear: this.curYear, usrId: store.state.user.sub, valueLst: [] };
        for (const el of this.dataLst) {
            for (const guEl of el.guLst) {
                if (guEl.value1 || guEl.value2 || guEl.value3) {
                    const obj = { gu: guEl.gu.gu, prg: el.prg.prg, value1: guEl.value1, value2: guEl.value2, value3: guEl.value3 }
                    saveObj.valueLst.push(obj);
                } 
            }
        }

        try {
            const url = '/api-py/budget-gkkp-limit-gu-save';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
            response = await response.json();
            for (const el of response) {
                if (el.result !== 'success') {
                    makeToast(this, 'danger', 'Ошибка сохранения', el.result);
                    return;
                }
            }
            makeToast(this, 'success', 'Сообщение', 'Данные сохранены');
            
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    /**
     * изменение checkbox
     * @param prgIndx 
     * @param guIndx 
     */
    private clkCheck(prgIndx: number | null, guIndx: number | null) {
        if (prgIndx === null) {
            for (const el of this.dataLst) {
                this.$set(el, 'checked', this.checkedAll);
                for (const elGu of el.guLst) {
                    this.$set(elGu, 'checked', this.checkedAll);
                }
            }
            return;
        }
        if (guIndx === null) {
            for (const elGu of this.dataLst[prgIndx].guLst) {
                this.$set(elGu, 'checked', this.dataLst[prgIndx].checked);
            }
            if (this.checkedAll && !this.dataLst[prgIndx].checked) {
                this.checkedAll = false;
            } else if (!this.checkedAll && this.dataLst[prgIndx].checked) {
                for (let i = 0; i < this.dataLst.length; i++) {
                    if (!this.dataLst[i].checked) {
                        return;
                    }
                }
                this.checkedAll = true;
            }
        } else {
            if (this.dataLst[prgIndx].checked && !this.dataLst[prgIndx].guLst[guIndx].checked) {
                this.dataLst[prgIndx].checked = false;
            }
        }
    }

    /**
     * удалить отмеченные
     */
    private delSelected() {
        let fl = false;
        for (let i = 0; i<this.dataLst.length; i++) {
            if (i < 0) { break; }
            if (this.dataLst[i].checked) {
                this.delRow(i, null);
                fl = true;
                i--;
            } else {
                for (let j=0; j<this.dataLst[i].guLst.length; j++) {
                    if (j<0) {break;}
                    if (this.dataLst[i].guLst[j].checked) {
                        this.delRow(i, j);
                        fl = true;
                        j--;
                    }
                }
            }
        }
        if (!fl) {
            makeToast(this, 'warning', 'Удаление', 'Нет отмеченных строк');
        }
    }

    /**
     * удалить строку
     * @param prgIndx индекс программы
     * @param guIndx индекс gu
     */
    private delRow(prgIndx: number, guIndx: number | null) {
        if (guIndx === null) {
            for (let i=1; i<4; i++) {
                this.totalSum[`value${i}`] -=this.dataLst[prgIndx][`value${i}`];
            }
            this.dataLst.splice(prgIndx, 1);
        } else {
            const guEl = this.dataLst[prgIndx].guLst[guIndx];
            this.dataLst[prgIndx].plan -= guEl.plan;
            for (let i=1; i<4; i++) {
                this.dataLst[prgIndx][`value${i}`] -= guEl[`value${i}`];
                this.totalSum[`value${i}`] -= guEl[`value${i}`];
            }
            
            this.dataLst[prgIndx].guLst.splice(guIndx, 1);
        }
    }
}
